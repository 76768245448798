import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import flattenDeep from 'lodash/flattenDeep';
import { MenuItem } from '@Umbraco/menu';
import routes, { getRoutesAs } from '@Routes';
import ETBIS from '@Static/images/etbis.svg';
import { RootState } from '@Redux';
import { useOrganizationType } from '@Helpers/organization/useOrganizationType';
import { MainMenuCategories, ProductKeys } from '@Constants/product';
import { FooterLinkType, FooterOrder, InternalLinksOrder } from '@Constants/footer';
import { Markets } from '@Constants/market';
import { INITIAL_FAQ_CATEGORY_SLUG } from '@Constants/faq';

const handleExternalLinks = menuItem =>
	menuItem?.externalLinks?.map((externalLink, index) => ({
		id: menuItem?.key + index,
		type: FooterLinkType.ITEM,
		loggedInArea: !externalLink?.publiclyAvailable,
		label: externalLink?.title,
		url: externalLink?.linkUrl,
		blank: true,
	})) || [];

const handleTechnicalData = (menuItem, t, technicalDataAvailable) => {
	return {
		type: FooterLinkType.GROUP,
		label: t('footer:publishers-developers'),
		hidden: !technicalDataAvailable,
		responsive: menuItem.responsive,
		children: [
			...handleExternalLinks(menuItem),
			{
				id: MainMenuCategories.TECHNICAL_DATA,
				type: FooterLinkType.ITEM,
				label: t('footer:technical-data'),
				responsive: menuItem.responsive,
				url: routes.TECHNICAL_DATA,
			},
		],
	};
};

const handleLinks = (menuItem, loggedInArea = false) => ({
	type: FooterLinkType.GROUP,
	label: menuItem.title,
	loggedInArea,
	url: !menuItem.children?.length && routes[menuItem.key],
	responsive: menuItem.responsive,
	id: !menuItem.children?.length && menuItem?.key,
	children: [
		...handleExternalLinks(menuItem),
		...menuItem.children?.map(child => ({
			id: child?.key,
			type: FooterLinkType.ITEM,
			label: child.title,
			url: routes[child.key],
			responsive: child.responsive,
		})),
	],
});

const getInternalLinks = (menuItem, t, technicalDataAvailable) => {
	if (!menuItem) return false;
	switch (menuItem.key) {
		case MainMenuCategories.RMI:
		case MainMenuCategories.DIAGNOSIS:
		case MainMenuCategories.MOBILE_APPS:
			return handleLinks(menuItem);
		case MainMenuCategories.TECHNICAL_DATA:
			return handleTechnicalData(menuItem, t, technicalDataAvailable);
		case MainMenuCategories.COMMUNICATION_EVENT:
			return handleLinks(menuItem, true);
		case MainMenuCategories.B2B_CONNECT_APP:
			return handleExternalLinks(menuItem);
		default:
			return handleLinks(menuItem);
	}
};

const handleB2bConnectApp = b2bConnectItem => {
	if (!b2bConnectItem) return null;
	const { externalLinks } = b2bConnectItem || {};
	const { title, linkUrl } = externalLinks?.[0];

	return {
		id: FooterOrder.B2B_CONNECT_APP,
		type: FooterLinkType.GROUP,
		label: title,
		url: linkUrl,
	};
};

export const useFooterData = (footerOrder, qrCode, t, language) => {
	const router = useRouter();
	const { order, internalLinksOrder } = footerOrder || {};
	const { market } = router.query;
	const qrCodeHidden = market !== Markets.TURKEY;
	const { technicalDataAvailable } = useOrganizationType();
	const menuItems = useSelector<RootState, MenuItem[]>(state => state.sections.headerContent.menuItems || []);
	const footerMenuExternalUrls = useSelector<RootState, MenuItem[]>(
		state => state.sections.footerContent.footerMenuExternalUrls
	);

	const footerDataMap = {
		[FooterOrder.NEWS]: {
			id: FooterOrder.NEWS,
			type: FooterLinkType.GROUP,
			label: t('common:news-title'),
			url: routes.NEWS,
		},
		[FooterOrder.LINKS]: [
			...(internalLinksOrder || Object.keys(InternalLinksOrder))
				.map(key =>
					getInternalLinks(
						menuItems.find(item => item.key === key),
						t,
						technicalDataAvailable
					)
				)
				.filter(item => item),
		],
		[FooterOrder.ADDITIONAL_INFO]: {
			type: FooterLinkType.GROUP,
			label: t('footer:additional-information'),
			children: footerMenuExternalUrls?.map((extUrl, index) => ({
				id: `${FooterOrder.ADDITIONAL_INFO}_${index}`,
				type: FooterLinkType.ITEM,
				url: extUrl.url,
				label: extUrl.title,
				blank: true,
			})),
		},
		[FooterOrder.ETBIS]: {
			id: FooterOrder.ETBIS,
			type: FooterLinkType.GROUP,
			label: t('footer:registered-etbis'),
			icon: <img style={{ height: 20 }} src={ETBIS} alt="ETBIS" />,
			hidden: qrCodeHidden,
			mobile: true,
			blank: true,
			url: qrCode?.website,
		},
		[FooterOrder.HELP]: {
			id: FooterOrder.HELP,
			type: FooterLinkType.GROUP,
			label: t('footer:need-help'),
			url: getRoutesAs(routes.FAQ_CATEGORY, { market, '...categories': INITIAL_FAQ_CATEGORY_SLUG }),
		},
		[FooterOrder.B2B_CONNECT_APP]: handleB2bConnectApp(
			menuItems.find(item => item.type === ProductKeys.B2B_CONNECT_APP)
		),
	};

	const data = useMemo(() => (order || Object.keys(footerDataMap)).map(key => footerDataMap[key]), [
		menuItems,
		footerMenuExternalUrls,
		footerOrder,
		qrCode,
		language,
	]);
	return flattenDeep(data);
};
